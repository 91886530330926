
import { Options, Vue } from 'vue-class-component'
import theCalendar from '@/components/Frontend/Calendar/index.vue'

@Options({
  components: {
    theCalendar
  }
})
export default class Calendar extends Vue {}
