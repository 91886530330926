import { Vue, Options } from 'vue-class-component'
import { Watch, Ref } from 'vue-property-decorator'
import CommonFunctions from '@/components/Utility/Common'
import BaseFunctions from '@/components/Utility/Base'
import { Events } from '@/services/EventsDataService'
import { Competitions } from '@/services/CompetitionsDataService'
import { Tournaments } from '@/services/TournamentsDataService'
import SysEvent, { SysEventEventType, SysEventClubId } from '@/types/SysEvent'
import SysCompetition from '@/types/SysCompetition'
import { SysTournamentMatchSchedule, SysTournamentCategoryId, SysTournamentRounds } from '@/types/SysTournament'
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar, { EventApi, EventInput } from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import da from '@fullcalendar/core/locales/da'
import timeGridWeek from '@fullcalendar/timegrid'
import Vue3Html2pdf from 'vue3-html2pdf'

type calendarDataType = { id: number; title: string; start: string; end: string; place: string; lastRegistrationDate: string; status: boolean; }
type dataReturnType = { calendarOptions: any, events: any, error: any, competitions: any; }

@Options({
  components: {
    FullCalendar,
    Vue3Html2pdf
  }
})
export default class theCalender extends Vue {
  error: any = null
  private events: SysEvent[] = []
  private competitions: SysCompetition[] = []
  private calendarDataType: calendarDataType = { id: 0, title: '', start: '', end: '', place: '', lastRegistrationDate: '', status: false }
  eventForCalendar: {title: string, start: string; displayDate: string}[] = []
  tempTournamentCategory: SysTournamentCategoryId[] = []
  calendarModal = false
  calendarType = ''
  includedInCalendar: { name: string, color: string, include: boolean, eventType: number | null }[] = [ // eventType: the id of the event included in the calendar, null is used when there is no events.
    { name: 'Stævner', color: '#3d9209', include: true, eventType: null },
    { name: 'Kurser', color: '#1e9ce3', include: true, eventType: 5 },
    { name: 'Klubarrangementer', color: '#e34a4a', include: true, eventType: 6 },
    { name: 'Bestyrelsesmøder', color: '#f79a36', include: true, eventType: 8 },
    { name: 'Udenlandske stævner', color: '#f7cc36', include: true, eventType: 7 },
    { name: 'Turneringsrunde', color: '#8c1dd1', include: true, eventType: null }
  ]

  pdfTitle = ''

  data (): dataReturnType {
    return {
      calendarOptions: {
        customButtons: {
          PrevView: {
            icon: 'chevron-left',
            click: () => {
              this.retrievePrev()
            },
            hint: 'Forrige'
          },
          NextView: {
            icon: 'chevron-right',
            click: () => {
              this.retrieveNext()
            },
            hint: 'Næste'
          },
          TodayView: {
            text: 'I dag',
            click: () => {
              this.retrieveToday()
            }
          },
          PDF: {
            text: 'PDF udskrift',
            click: () => {
              this.downloadPdf()
            }
          }
        },
        headerToolbar: {
          center: 'dayGridMonth,timeGridWeek',
          right: 'PDF,TodayView PrevView,NextView'
        },
        plugins: [dayGridPlugin, timeGridWeek],
        initialView: 'dayGridMonth',
        // lazyFetching: false,
        // events: this.calendar,
        locale: da,
        timeZone: 'DK',
        eventClick: (info : any) => {
          this.calendarInfo(info.event.id, info.event.extendedProps.type, info.event.extendedProps.enableModal)
        },
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit'
        },
        weekNumbers: true,
        dayHeaderFormat: {
          weekday: 'long'
        }
      },
      events: this.events,
      error: this.error,
      competitions: this.competitions
    }
  }

  @Watch('calendarModal')
  onModalStatusChange (toggleValue: boolean) : void {
    if (toggleValue === false) {
      this.calendarDataType = { id: 0, title: '', start: '', end: '', place: '', lastRegistrationDate: '', status: false }
      this.calendarType = ''
    }
  }

  public changeCalendarView (i: number) : void {
    this.includedInCalendar[i].include = !this.includedInCalendar[i].include
    this.retrieveCalendar()
  }

  public createPdfTitle () : void {
    const calendarApi = (this.$refs.fullCalender as InstanceType<typeof FullCalendar>).getApi()
    console.log(calendarApi.currentData.currentDate.toLocaleDateString('da-DK', { month: 'long' }))
    this.pdfTitle = calendarApi.currentData.currentDate.toLocaleDateString('da-DK', { month: 'long' })
  }

  public downloadPdf () : void {
    const pdfApi = this.$refs.html2Pdf as any
    pdfApi.generatePdf()
  }

  public constructSlug (id: number, title: string, date: string) : string {
    return id.toString() + '_' + this.convertToSlug(title) + '-' + new Date(date).toLocaleDateString('da-DK').replaceAll('.', '-')
  }

  public convertHTMLToText (htmlData: string) : string {
    return BaseFunctions.convertHTMLToText(htmlData)
  }

  public convertToSlug (text : string) : string {
    return CommonFunctions.slugify(text)
  }

  public capitalizeFirstLetter (string = '') : string {
    return (string !== undefined && string.length >= 1 ? string[0].toUpperCase() + string.slice(1) : '')
  }

  public locConvertFromUTCtoLocalDateTime (inputDatetimeString: string) : string {
    let retVal = ''
    // console.log('[locConvertFromUTCtoLocalDateTime()] inputDatetimeString = ' + inputDatetimeString)
    retVal = CommonFunctions.convertFromUTCtoLocalDateTime(inputDatetimeString, 0)
    const strPos = retVal.indexOf(' ')
    retVal = (retVal.substring(0, strPos) + 'T' + retVal.substring(strPos + 1)).substring(0, 19)

    return retVal
  }

  public danishDataWClock (dateString: string) : string {
    return CommonFunctions.toDanishDateString(dateString, 3) + ' kl. ' + CommonFunctions.getHoursAndMinutes(dateString, false)
  }

  public danishDataWClock2 (dateString: string) : string {
    return CommonFunctions.toDanishDateString(dateString, 6) + ', klokken ' + CommonFunctions.getHoursAndMinutes(dateString, false)
  }

  public getTimeOfDay (dateString: string) : string {
    return CommonFunctions.getHoursAndMinutes(dateString)
  }

  public calendarInfo (id : number, type: string, enableModal: boolean) : void {
    if (enableModal === false) {
      return
    }
    this.calendarType = type
    this.calendarModal = true

    if (type === 'event') {
      let tempEvent = {} as SysEvent
      Events.EventsDataService.get(id.toString())
        .then((response) => {
          tempEvent = response.data

          this.calendarDataType = {
            id: Number(tempEvent.id),
            title: (tempEvent.event_type_id.id === 6 ? tempEvent.event_titel + ' hos ' + tempEvent.klubber_id.klubber_klubnavn : tempEvent.event_titel),
            start: this.locConvertFromUTCtoLocalDateTime(tempEvent.event_start),
            end: this.locConvertFromUTCtoLocalDateTime(tempEvent.event_slut),
            place: tempEvent.event_sted,
            lastRegistrationDate: '',
            status: tempEvent.event_status
          }
        })
        .catch((err) => {
          this.error = err
        })
    }

    if (type === 'competition') {
      let tempCompetition = {} as SysCompetition

      Competitions.CompetitionsDataService.get(id.toString())
        .then((response) => {
          tempCompetition = response.data

          this.calendarDataType = {
            id: Number(tempCompetition.id),
            title: tempCompetition.staevne_navn,
            start: this.locConvertFromUTCtoLocalDateTime(tempCompetition.staevne_start),
            end: this.locConvertFromUTCtoLocalDateTime(tempCompetition.staevne_slut),
            place: tempCompetition.staevne_spillested,
            lastRegistrationDate: tempCompetition.staevne_sidstetilmelding,
            status: tempCompetition.staevne_status
          }
          console.log(this.calendarDataType)
        })
        .catch((err) => {
          this.error = err
          console.log('Error: ' + this.error)
        })
    }
  }

  public retrieveToday () : void {
    const calendarApi = (this.$refs.fullCalender as InstanceType<typeof FullCalendar>).getApi()

    calendarApi.today()
    this.retrieveCalendar()
  }

  public retrieveNext () : void {
    const calendarApi = (this.$refs.fullCalender as InstanceType<typeof FullCalendar>).getApi()

    calendarApi.next()
    this.retrieveCalendar()
  }

  public retrievePrev () : void {
    const calendarApi = (this.$refs.fullCalender as InstanceType<typeof FullCalendar>).getApi()

    calendarApi.prev()
    this.retrieveCalendar()
  }

  public getCalendarColor (eventId: number) : string {
    const index = this.includedInCalendar.findIndex(element => element.eventType === eventId)

    if (index !== -1) {
      return this.includedInCalendar[index].color
    }

    return '#aa17cf'
  }

  public retrieveCalendar () : void {
    const calendarApi = (this.$refs.fullCalender as InstanceType<typeof FullCalendar>).getApi()
    const start = new Date(calendarApi.currentData.dateProfile.currentRange.start).toISOString()
    const end = new Date(calendarApi.currentData.dateProfile.currentRange.end).toISOString()
    let eventType = ''
    let fetchCompetitions: { fetch: boolean, color: string} = { fetch: false, color: '' }
    let fetchTournaments: { fetch: boolean, color: string } = { fetch: false, color: '' }
    let tempEvents: SysEvent[] = []
    let tempCompetition: SysCompetition[] = []
    let tempTournamentSchedule: SysTournamentMatchSchedule[] = []
    let tempTournamentRounds: SysTournamentRounds[] = []

    this.createPdfTitle()
    this.eventForCalendar = []

    for (const item of this.includedInCalendar) {
      if (item.eventType !== null && item.include) {
        eventType += `&event_type_id.id=${item.eventType}`
      }
      if (item.name === 'Stævner' && item.include) {
        fetchCompetitions = { fetch: true, color: item.color }
      }
      if (item.name === 'Turneringsrunde' && item.include) {
        fetchTournaments = { fetch: true, color: item.color }
      }
    }

    // Removes all events from the calendar, this is in order to prevent the same event appearing multiple times, when user click back and forth through the dates.
    calendarApi.removeAllEvents()
    if (eventType.length > 0) {
      Events.EventsDataService.getAll('', null, `event_status=true&event_start_gte=${start}&event_start_lte=${end}${eventType}`)
        .then((response) => {
          tempEvents = response.data
          const calendarEvents: EventInput[] = []

          for (const item of tempEvents) {
            calendarEvents.push({
              id: (item.id === null ? '0' : Number(item.id).toString()),
              title: (item.event_type_id.id === 6 ? item.event_titel + ' hos ' + item.klubber_id.klubber_klubnavn : item.event_titel),
              start: this.locConvertFromUTCtoLocalDateTime(item.event_start),
              end: this.locConvertFromUTCtoLocalDateTime(item.event_slut),
              type: 'event',
              backgroundColor: this.getCalendarColor(Number(item.event_type_id.id)),
              enableModal: true
            })
            this.eventForCalendar.push({
              title: (item.event_type_id.id === 6 ? item.event_titel + ' hos ' + item.klubber_id.klubber_klubnavn : item.event_titel),
              start: (item.event_start !== null && item.event_start.length >= 1 ? this.danishDataWClock(this.locConvertFromUTCtoLocalDateTime(item.event_start)) : '-'),
              displayDate: this.locConvertFromUTCtoLocalDateTime(item.event_start)
            })
          }

          this.eventForCalendar = this.eventForCalendar.sort((a, b) => new Date(a.displayDate).getTime() - new Date(b.displayDate).getTime())
          calendarApi.addEventSource(calendarEvents)
        })
        .catch((err) => {
          this.error = err
          console.log('Error: ' + this.error)
        })
    }
    if (fetchCompetitions.fetch) {
      Competitions.CompetitionsDataService.getAll('', null, `staevne_start_gte=${start}&staevne_start_lte=${end}`)
        .then((response) => {
          tempCompetition = response.data
          const calendarCompetitions: EventInput[] = []

          for (const item of tempCompetition) {
            calendarCompetitions.push({
              id: (item.id === null ? '0' : Number(item.id).toString()),
              title: item.staevne_navn,
              start: this.locConvertFromUTCtoLocalDateTime(item.staevne_start),
              end: this.locConvertFromUTCtoLocalDateTime(item.staevne_slut),
              type: 'competition',
              backgroundColor: fetchCompetitions.color,
              enableModal: true
            })
            this.eventForCalendar.push({
              title: item.staevne_navn,
              start: (item.staevne_start !== null && item.staevne_start.length >= 1 ? this.danishDataWClock(this.locConvertFromUTCtoLocalDateTime(item.staevne_start)) : '-'),
              displayDate: this.locConvertFromUTCtoLocalDateTime(item.staevne_start)
            })
          }

          this.eventForCalendar = this.eventForCalendar.sort((a, b) => new Date(a.displayDate).getTime() - new Date(b.displayDate).getTime())
          calendarApi.addEventSource(calendarCompetitions)
        })
        .catch((err) => {
          this.error = err
          console.log('Error: ' + this.error)
        })
    }
    if (fetchTournaments.fetch) {
      Tournaments.TournamentCategoriesDataService.getAll('', null, '')
        .then((response) => {
          this.tempTournamentCategory = response.data

          Tournaments.TournamentRoundsDataService.getAll('', null, '')
            .then((response) => {
              tempTournamentRounds = response.data

              Tournaments.TournamentMatchSchedulesDataService.getAll('', null, `kampprogram_dato_gte=${start}&kampprogram_dato_lte=${end}`)
                .then((response) => {
                  tempTournamentSchedule = response.data
                  const calendarTournament: EventInput[] = []

                  // FIXME: A temporary workaround. Should be made undone later. [DONE]
                  if (tempTournamentSchedule.length > 0) {
                    // if (tempTournamentSchedule.length > 0 && ((new Date(calendarApi.currentData.dateProfile.currentRange.start)).getTime() + 4 * 24 * 60 * 60 * 1000) < (new Date(2023, 12, 1, 1, 1, 0)).getTime()) {
                    console.log('Using tournament schedule information.')
                    console.log('tempTournamentSchedule = ' + JSON.stringify(tempTournamentSchedule))
                    for (const item of tempTournamentSchedule) {
                      const index = calendarTournament.findIndex(el => el.start === new Date(item.kampprogram_dato).toISOString().split('T')[0] && el.groupId === item.raekke_id.turneringskategori_id.toString())

                      if (index === -1) {
                        calendarTournament.push({
                          id: (item.id === null ? '0' : Number(item.id).toString()),
                          title: this.findCategoryTitle(item.raekke_id.turneringskategori_id),
                          start: new Date(this.locConvertFromUTCtoLocalDateTime(item.kampprogram_dato)).toISOString().split('T')[0],
                          type: 'tournament',
                          groupId: item.raekke_id.turneringskategori_id.toString(),
                          backgroundColor: fetchTournaments.color,
                          enableModal: false
                        })
                        this.eventForCalendar.push({
                          title: this.findCategoryTitle(item.raekke_id.turneringskategori_id),
                          start: (item.kampprogram_dato !== null && item.kampprogram_dato.length >= 1 ? this.danishDataWClock(this.locConvertFromUTCtoLocalDateTime(item.kampprogram_dato)) : '-'),
                          displayDate: this.locConvertFromUTCtoLocalDateTime(item.kampprogram_dato)
                        })
                      }
                    }
                  } else {
                    console.log('Using tournament rounds information.')
                    for (const item of tempTournamentRounds) {
                      const index = calendarTournament.findIndex(el => el.start === new Date(item.turneringsrunder_dato).toISOString().split('T')[0] && Number(el.groupId) === Number(item.turneringskategori_id.id))

                      if (index === -1) {
                        calendarTournament.push({
                          id: (item.id === null ? '0' : Number(item.id).toString()),
                          title: this.findCategoryTitle(Number(item.turneringskategori_id.id)),
                          start: new Date(this.locConvertFromUTCtoLocalDateTime(item.turneringsrunder_dato)).toISOString().split('T')[0],
                          type: 'tournament',
                          groupId: Number(item.turneringskategori_id.id).toString(),
                          backgroundColor: fetchTournaments.color,
                          enableModal: false
                        })
                        this.eventForCalendar.push({
                          title: this.findCategoryTitle(Number(item.turneringskategori_id.id)),
                          start: (item.turneringsrunder_dato !== null && item.turneringsrunder_dato.length >= 1 ? this.danishDataWClock(this.locConvertFromUTCtoLocalDateTime(item.turneringsrunder_dato)) : '-'),
                          displayDate: this.locConvertFromUTCtoLocalDateTime(item.turneringsrunder_dato)
                        })
                      }
                    }
                  }

                  this.eventForCalendar = this.eventForCalendar.sort((a, b) => new Date(a.displayDate).getTime() - new Date(b.displayDate).getTime())
                  calendarApi.addEventSource(calendarTournament)
                })
                .catch((err) => {
                  this.error = err
                  console.log('Error: ' + this.error)
                })
            })
            .catch((err) => {
              this.error = err
              console.log('Error: ' + this.error)
            })
        })
        .catch((err) => {
          this.error = err
          console.log('Error: ' + this.error)
        })
    }
  }

  public findCategoryTitle (categoryId: number) : string {
    let retVal = '???'

    const index = this.tempTournamentCategory.findIndex(el => Number(el.id) === Number(categoryId))
    if (index !== -1) {
      retVal = this.tempTournamentCategory[index].turneringskategori_navn
    }

    return retVal
  }

  async mounted () : Promise<void> {
    this.retrieveCalendar()
    // this.calendarApi = (this.$refs.fullCalender as InstanceType<typeof FullCalendar>).getApi()
  }
}
